.App {
  text-align: center;
}

.pawn {
  height: 12vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes pawn-move {
  0%, 100% {
      transform: translateY(0);
      animation-timing-function: ease-in-out;
  }
  20%, 80% {
      transform: translateY(-70%);
      animation-timing-function: ease;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .pawn {
      animation: pawn-move 1.8s infinite;
  }
}
